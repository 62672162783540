import React from 'react';
import NewsGrid from './NewsGrid';
import BulletinStory from './BulletinStory';

const WorldNewsSection = () => {
  const newsItems = [
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6ijY_jm4xjCj0xK3SXR_RJFNY1F3Qe8SJNw&s',
      title: 'New Moneypox virus found in Thailand',
      description: '',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR01pvMvoIqpXCiPET_be5SLkl3GnrOfMPDOQ&s',
      title: 'US Students Detained at Van Vihar Illege, Suroday Tv',
      description: '',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR01pvMvoIqpXCiPET_be5SLkl3GnrOfMPDOQ&s',
      title: 'UGC NET 2004 ABVP Demands Reopening of..',
      description: '',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSANWAbLoU-8oIgdcvGXQWirpNubTk1urchYA&s',
      title: 'Bhopal : Students Detained at Van Vihar Illege, Suroday Tv',
      description: '',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6ijY_jm4xjCj0xK3SXR_RJFNY1F3Qe8SJNw&s',
      title: 'New Moneypox virus found in Thailand',
      description: '',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR01pvMvoIqpXCiPET_be5SLkl3GnrOfMPDOQ&s',
      title: 'US Students Detained at Van Vihar Illege, Suroday Tv',
      description: '',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR01pvMvoIqpXCiPET_be5SLkl3GnrOfMPDOQ&s',
      title: 'UGC NET 2004 ABVP Demands Reopening of..',
      description: '',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSANWAbLoU-8oIgdcvGXQWirpNubTk1urchYA&s',
      title: 'Bhopal : Students Detained at Van Vihar Illege, Suroday Tv',
      description: '',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6ijY_jm4xjCj0xK3SXR_RJFNY1F3Qe8SJNw&s',
      title: 'New Moneypox virus found in Thailand',
      description: '',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR01pvMvoIqpXCiPET_be5SLkl3GnrOfMPDOQ&s',
      title: 'US Students Detained at Van Vihar Illege, Suroday Tv',
      description: '',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR01pvMvoIqpXCiPET_be5SLkl3GnrOfMPDOQ&s',
      title: 'UGC NET 2004 ABVP Demands Reopening of..',
      description: '',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSANWAbLoU-8oIgdcvGXQWirpNubTk1urchYA&s',
      title: 'Bhopal : Students Detained at Van Vihar Illege, Suroday Tv',
      description: '',
    },
  ];

  const stories = [
    {
      image:
        'https://yt3.googleusercontent.com/y_esGAQOhX4rTpWvrALErAJlFbm_2TIVrvcVfcZny7TuA8dJZgOQcC6KRfd_J5hljFe-foYXj9U=s900-c-k-c0x00ffffff-no-rj',
      name: 'bbcnews',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTa1t5u46vZwRLR5ltjOXXnfaxFbHj8B99sIg&s',
      name: 'ecommurz',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0UsFt9bP4c6M_aIsVduKMoIPk-MSW6aB7Dw&s',
      name: 'formula_one',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJMo3xKqUFFbynD2OoXazX46IvoT_qN4qvhw&s',
      name: 'goal',
    },
    {
      image:
        'https://devimages-cdn.apple.com/wwdc-services/articles/images/4E5C0028-D1D4-4586-A736-23182F2E62C7/2048.jpeg',
      name: 'apple',
    },
    {
      image:
        'https://bsmedia.business-standard.com/_media/bs/img/about-page/1562575696.png?im=FitAndFill=(826,465)',
      name: 'samsung',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCQxdyBRvQuF6Y1zcE6JoQqxAVf8PXmuO-JQ&s',
      name: 'idntimes',
    },
    {
      image:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7hwl0_ov5JTaOhCJFpsKbiV1FZQM_Jshg7g&s',
      name: 'kretyastudio',
    },
    {
      image:
        'https://yt3.googleusercontent.com/ytc/AIdro_luNZU5H-RhFxhbgWBn0FmH295pgHf-Tw49QSzAxKzBcEI=s900-c-k-c0x00ffffff-no-rj',
      name: 'fitra.eri',
    },
  ];

  return (
    <div className="p-6 bg-white">
      <h2 className="text-xl font-bold text-secondary mb-4">World</h2>
      <NewsGrid newsItems={newsItems} />
      <div className="flex justify-between items-center mt-8">
        <h3 className="text-lg font-semibold text-secondary">Buletin Story</h3>
        <a href="#" className="text-sm text-yellow-500">
          View All
        </a>
      </div>
      <BulletinStory stories={stories} />
    </div>
  );
};

export default WorldNewsSection;
