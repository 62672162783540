import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';

import Topbar from './components/Topbar';
import Topbarbanner from './components/Topbarbanner';
import Login from './pages/Login';
import Footer from './components/Footer';
import Home from './pages/Home/Home';

function App() {
  const location = useLocation();

  // Check if the current route is the login route
  const isLoginRoute = location.pathname === '/';

  return (
    <div className="container px-2">
      {/* Only render Topbar, Topbarbanner, and Navbar if not on the login route */}
      {!isLoginRoute && (
        <>
          <Topbar />
          <Topbarbanner />
          <Navbar />
        </>
      )}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        {/* Add more routes as needed */}
      </Routes>
      {!isLoginRoute && (
        <>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
