import React from 'react';
import HomeBanner from './HomeBanner';
import NewsLayout from './NewsLayout';
import NewsArticlesHindi from './NewsArticlesHindi';
import WorldNewsSection from './WorldNewsSection';

const Home = () => {
  return (
    <div>
      <HomeBanner />
      <NewsLayout />
      <NewsArticlesHindi />
      <div className="min-h-screen bg-gray-100">
        <WorldNewsSection />
      </div>
    </div>
  );
};

export default Home;
