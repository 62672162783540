import React from 'react';

const NewsItem = ({ image, title, description }) => {
  return (
    <div className="flex space-x-4 mb-4">
      <img
        src={image}
        alt={title}
        className="w-24 h-16 object-cover"
      />
      <div>
        <h3 className="text-sm font-semibold text-secondary">
          {title}
        </h3>
        <p className="text-xs text-gray-600">{description}</p>
      </div>
    </div>
  );
};

export default NewsItem;
