import React from 'react';
import logo from '../assets/logo.svg';
function Footer() {
  return (
    <footer className="bg-black text-white py-10 flex flex-col">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center px-40 lg:items-center">
        {/* Logo Section */}
        <div className="mb-8 lg:mb-0">
          <img src={logo} alt="Suryoday TV" className="w-32" />
        </div>

        {/* Links Section */}
        <div>
          <h3 className="text-yellow-500 font-semibold mb-4">
            LIVE NEWS TODAY
          </h3>
          <ul className="space-y-2">
            <li>
              <a href="/mp-news" className="hover:text-yellow-500">
                M.P. NEWS
              </a>
            </li>
            <li>
              <a href="/politics" className="hover:text-yellow-500">
                POLITICS
              </a>
            </li>
            <li>
              <a href="/crime" className="hover:text-yellow-500">
                CRIME
              </a>
            </li>
            <li>
              <a href="/business" className="hover:text-yellow-500">
                BUSINESS
              </a>
            </li>
            <li>
              <a href="/entertainment" className="hover:text-yellow-500">
                ENTERTAINMENT
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-yellow-500 font-semibold mb-4">
            PIN CODE FINDER
          </h3>
          <ul className="space-y-2">
            <li>
              <a href="/mp-pincodes" className="hover:text-yellow-500">
                M.P. PINCODES
              </a>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </li>
          </ul>
        </div>
        <div>
          <h3 className="text-white font-semibold mb-4">Quick Links</h3>
          <ul className="space-y-2">
            <li>
              <a href="/about-us" className="hover:text-yellow-500">
                About Us
              </a>
            </li>
            <li>
              <a href="/career" className="hover:text-yellow-500">
                Career
              </a>
            </li>
            <li>
              <a href="/advertise-with-us" className="hover:text-yellow-500">
                Advertise With Us
              </a>
            </li>
            <li>
              <a href="/disclaimer" className="hover:text-yellow-500">
                Disclaimer
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Subscribe Section */}
      <div className=" flex justify-center items-center py-10">
        <div className="flex">
          <input
            type="email"
            placeholder="Get Daily Updates on Your Hand"
            className="px-4 py-2 rounded-l-full text-black"
          />
          <button className="bg-yellow-500 text-black px-4 py-2 rounded-r-full">
            Subscribe
          </button>
        </div>
      </div>
      {/* Copyright Section */}
      <div className="text-center ">
        <p className="text-gray-400">Copyright©2024. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
