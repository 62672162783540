import React from 'react';
import imge1 from '../../assets/Mask Group 37.png';
import margi from '../../assets/istockphoto-1369150014-612x612.jpg';
import { FaFacebookF, FaTwitter } from 'react-icons/fa';
import { LuLink } from 'react-icons/lu';
const NewsArticlesHindi = () => {
  return (
    <div>
      {/* News Articles in Hindi */}

      <div className="grid md:grid-cols-12 grid-cols-1 gap-5 px-5">
        <div className="grid col-span-3">
          <img src={imge1} alt="News" className="w-60 h-auto object-cover" />
        </div>
        <div className="space-y-4 col-span-8">
          <div className="text-secondary">
            <div className="flex justify-between md:flex-row flex-reverse ">
              <h3 className="text-md font-bold  text-xl">
                <span className="text-red-500">
                  42 साल की शादीशुदा महिला से रेप:
                </span>{' '}
                <br />
                मार्केट जाने के दौरान रास्ते में चाय पिलाने के बहाने बनाया शिकार
              </h3>
              <div>
                <img src={margi} alt="" className="w-20 h-20" />
              </div>
            </div>
            <div className="flex justify-between py-5">
              <p className="text-sm text-gray-600">इंदौर</p>

              <div className="flex gap-5 text-gray-500">
                <FaFacebookF />
                <LuLink />
                <FaTwitter />
              </div>
            </div>
          </div>
          <div className="text-secondary">
            <div className="flex justify-between md:flex-row flex-reverse ">
              <h3 className="text-md font-bold  text-xl">
                <span className="text-red-500">पुलिस ने सुनाई अनोखी सजा:</span>{' '}
                <br />
                कारों के कांच फोड़ने वाले बदमाशों को अब सालभर ट्रैफिक सिग्नलों का
                जिम्मा
              </h3>
              <div>
                <img src={margi} alt="" className="w-20 h-20" />
              </div>
            </div>
            <div className="flex justify-between py-5">
              <p className="text-sm text-gray-600">इंदौर</p>

              <div className="flex gap-5 text-gray-500">
                <FaFacebookF />
                <LuLink />
                <FaTwitter />
              </div>
            </div>
          </div>
          <div className="text-secondary">
            <div className="flex justify-between md:flex-row flex-reverse ">
              <h3 className="text-md font-bold  text-xl">
                <span className="text-red-500">पुलिस ने सुनाई अनोखी सजा:</span>{' '}
                <br />
                कारों के कांच फोड़ने वाले बदमाशों को अब सालभर ट्रैफिक सिग्नलों का
                जिम्मा
              </h3>
              <div>
                <img src={margi} alt="" className="w-20 h-20" />
              </div>
            </div>
            <div className="flex justify-between py-5">
              <p className="text-sm text-gray-600">इंदौर</p>

              <div className="flex gap-5 text-gray-500">
                <FaFacebookF />
                <LuLink />
                <FaTwitter />
              </div>
            </div>
          </div>
          <div className="text-secondary">
            <div className="flex justify-between md:flex-row flex-reverse ">
              <h3 className="text-md font-bold  text-xl">
                <span className="text-red-500">पुलिस ने सुनाई अनोखी सजा:</span>{' '}
                <br />
                कारों के कांच फोड़ने वाले बदमाशों को अब सालभर ट्रैफिक सिग्नलों का
                जिम्मा
              </h3>
              <div>
                <img src={margi} alt="" className="w-20 h-20" />
              </div>
            </div>
            <div className="flex justify-between py-5">
              <p className="text-sm text-gray-600">इंदौर</p>

              <div className="flex gap-5 text-gray-500">
                <FaFacebookF />
                <LuLink />
                <FaTwitter />
              </div>
            </div>
          </div>
        </div>{' '}
      </div>
    </div>
  );
};

export default NewsArticlesHindi;
