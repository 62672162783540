import React from 'react';
import { FaMusic, FaRegPlayCircle, FaSignInAlt, FaVideo } from 'react-icons/fa';
import logo from '../assets/logo.svg';
const Topbar = () => {
  return (
    <nav className="bg-black p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-2xl font-bold">
          <img src={logo} alt="" className='w-32 h-20'/>
        </div>

        <div className="flex items-center gap-5">
          <a
            href="/"
            className="text-white bg-primary text-center rounded-2xl p-2 mt-4 lg:mt-0 px-4 flex items-center gap-2"
          >
            <FaMusic />
            <span>Audio</span>
          </a>
          <a
            href="/mp"
            className="text-white bg-primary rounded-2xl p-2 mt-4 lg:mt-0 px-4 flex items-center gap-2"
          >
            <FaVideo />
            <span>Video</span>
          </a>
          <a
            href="/viral-videos"
            className="text-white bg-primary rounded-2xl p-2 mt-4 lg:mt-0 px-4 flex items-center gap-2"
          >
            <FaRegPlayCircle />
            <span>Programs</span>
          </a>
          <a
            href="/politics"
            className="text-white mt-4 lg:mt-0 px-4 flex items-center gap-2"
          >
            <FaSignInAlt />
            <span>Login</span>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Topbar;
