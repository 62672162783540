import React from 'react';
import advertisement_image from '../../assets/advtament.png';
import Mask1 from '../../assets/Mask Group 35.png';
import Mask2 from '../../assets/Mask Group 34.png';

const NewsLayout = () => {
  return (
    <div className="flex flex-col md:flex-row p-4 bg-white">
      {/* Left Section */}
      <div className="md:w-2/3 w-full space-y-4">
        {/* News 1 */}
        <div className="flex space-x-4">
          <img src={Mask1} alt="News" className="w-1/3 h-auto object-cover" />
          <div>
            <h2 className="text-lg font-semibold text-secondary">
              The rooftop of old vintage villa in Gazibad got collapsed 1 Died 4
              Injured.
            </h2>
            <p className="text-sm text-gray-600">4:00pm, 22 Aug 2024</p>
          </div>
        </div>

        {/* News 2 */}
        <div className="flex space-x-4">
          <img src={Mask2} alt="News" className="w-1/3 h-auto object-cover" />
          <div>
            <h2 className="text-lg font-semibold text-secondary">
              Police Officials got attacked By group of Terrorist at J&K
            </h2>
            <p className="text-sm text-gray-600">08:30 am, 22 Aug 2024</p>
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="md:w-1/3 w-full mt-4 md:mt-0 md:pl-4">
        <div className="space-y-4">
          {/* Advertisement */}
          <div className=" p-4">
            <img
              src={advertisement_image}
              alt="Advertisement"
              className=" object-contain w-40 h-40"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLayout;
