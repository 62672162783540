import React from 'react';
import NewsItem from './NewsItem';

const NewsGrid = ({ newsItems }) => {
  return (
    <div className="grid md:grid-cols-2 grid-cols-1 gap-4 ">
      {newsItems.map((item, index) => (
        <NewsItem
          key={index}
          image={item.image}
          title={item.title}
          description={item.description}
        />
      ))}
    </div>
  );
};

export default NewsGrid;
