import React from 'react';
import gov from '../../assets/Grov.png';
import baner2 from '../../assets/baner2.png';
import disc99 from '../../assets/disc99.png';
const HomeBanner = () => {
  return (
    <div className="flex justify-center flex-col items-center">
      <div>
        <img src={gov} alt="" />
      </div>
      <div>
        <img src={baner2} alt="" />
      </div>

      <div className="bg-[#F8F8F6] w-[100%] h-96  flex justify-end  items-center">
        <img src={disc99} alt="" className="" />
      </div>
    </div>
  );
};

export default HomeBanner;
