import React from 'react';
import winbook from '../assets/winbook.png';
import Digital from '../assets/Digital-Job-Advertising-Strategies-Bootcamp-Horizontal-Ad.png';
import { IoCall, IoLogoWhatsapp } from 'react-icons/io5';
const Topbarbanner = () => {
  return (
    <div className="grid md:grid-cols-12 grid-cols-1 gap-2 items-center text-center ">
      <div className="col-span-2"></div>
      <div className="col-span-4">
        <img src={winbook} alt="" />
      </div>
      <div className="col-span-4">
        <img src={Digital} alt="" />
      </div>
      <div className="col-span-2">
        <div className="bg-primary  rounded-xl">
          <h2>CONTACT</h2>
          <div className="flex items-center justify-center gap-5">
            <IoCall />
            <IoLogoWhatsapp className="text-green-600" />
          </div>
          <div>+9185555-80000</div>
          <div>+9185555-80000</div>
        </div>
      </div>
    </div>
  );
};

export default Topbarbanner;
