import React, { useState } from 'react';
import {
  FaBriefcase,
  FaGavel,
  FaGlobe,
  FaHome,
  FaLandmark,
  FaVideo,
} from 'react-icons/fa';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className=" p-4">
      <div className="container mx-auto ">
        <div className="block lg:hidden">
          <button onClick={() => setIsOpen(!isOpen)} className="">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
        <div
          className={`lg:flex lg:items-center gap-5  text-secondary justify-center items-center w-full ${
            isOpen ? 'block' : 'hidden'
          }`}
        >
          <a
            href="/"
            className="flex items-center  mt-4 lg:mt-0 px-4 border-2 border-borderColor rounded-2xl"
          >
            <FaHome className="mr-2" /> Home
          </a>
          <a
            href="/mp"
            className="flex items-center  mt-4 lg:mt-0 px-4 border-2 border-borderColor rounded-2xl"
          >
            <FaGlobe className="mr-2" /> M.P
          </a>
          <a
            href="/viral-videos"
            className="flex items-center  mt-4 lg:mt-0 px-4 border-2 border-borderColor rounded-2xl"
          >
            <FaVideo className="mr-2" /> Viral Videos
          </a>
          <a
            href="/politics"
            className="flex items-center  mt-4 lg:mt-0 px-4 border-2 border-borderColor rounded-2xl"
          >
            <FaLandmark className="mr-2" /> Politics
          </a>
          <a
            href="/crime"
            className="flex items-center  mt-4 lg:mt-0 px-4 border-2 border-borderColor rounded-2xl"
          >
            <FaGavel className="mr-2" /> Crime
          </a>
          <a
            href="/business"
            className="flex items-center  mt-4 lg:mt-0 px-4 border-2 border-borderColor rounded-2xl"
          >
            <FaBriefcase className="mr-2" /> Business
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
