import React from 'react';

const BulletinStory = ({ stories }) => {
  return (
    <div className="flex items-center space-x-4 mt-8 justify-center flex-wrap">
      {stories.map((story, index) => (
        <div key={index} className="flex flex-col items-center">
          <img
            src={story.image}
            alt={story.name}
            className="w-16 h-16 rounded-full border-2 border-yellow-500"
          />
          <p className="text-xs text-center mt-2">{story.name}</p>
        </div>
      ))}
    </div>
  );
};

export default BulletinStory;
