// src/components/Login.js
import React from 'react';
import { Link } from 'react-router-dom';
import path_to_logo from '../assets/logo.svg';
const Login = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-black">
      <div className="text-center">
        <img
          src={path_to_logo}
          alt="Suryoday TV Logo"
          className="mx-auto mb-8"
        />
        <h1 className="text-yellow-500 text-2xl mb-6">WELCOME !</h1>
        <form className="space-y-4">
          <div className="flex items-center   rounded-lg overflow-hidden">
            <span className=" bg-white px-4 mx-4 py-2 rounded-tl-2xl rounded-bl-2xl rounded-br-2xl text-black">
              91+
            </span>
            <input
              type="text"
              placeholder="Enter your Phone No"
              className="w-full py-2  text-black px-4 bg-white outline-none rounded-tr-2xl rounded-bl-2xl rounded-br-2xl"
            />
          </div>
          <div className="flex items-center justify-center space-x-2 py-5">
            <input
              type="checkbox"
              id="terms"
              className="form-checkbox text-yellow-500  w-3 h-3 rounded-full"
            />
            <label htmlFor="terms" className="text-white text-sm">
              I have read & accepted the
              <a href="#" className="text-blue-400">
                T&C
              </a>
              & the
              <a href="#" className="text-blue-400">
                privacy & policy
              </a>
              for use of Suryoday TV
            </label>
          </div>
          <Link to="/home">
            <button
              type="submit"
              className=" py-2 bg-yellow-500 text-black rounded-lg hover:bg-yellow-400 transition-colors w-fit px-10"
            >
              Login
            </button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Login;
